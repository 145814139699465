/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
require('@fontsource/lato/100.css');
require('@fontsource/lato/300.css');
require('@fontsource/lato');
require('@fontsource/lato/700.css');
require('@fontsource/lato/900.css');
// normalize CSS across browsers
require('./src/normalize.css');
// custom CSS styles
require('./src/bootstrap-custom.css');
require('./src/style.css');

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);
  setTimeout(() => { window.scrollTo(...(currentPosition || [0, 0])); }, 100);

  return false;
};
