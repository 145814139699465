exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-lessons-js": () => import("./../../../src/pages/book-lessons.js" /* webpackChunkName: "component---src-pages-book-lessons-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-holidays-and-closures-js": () => import("./../../../src/pages/holidays-and-closures.js" /* webpackChunkName: "component---src-pages-holidays-and-closures-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-safeguarding-js": () => import("./../../../src/pages/safeguarding.js" /* webpackChunkName: "component---src-pages-safeguarding-js" */),
  "component---src-pages-swim-academy-js": () => import("./../../../src/pages/swim-academy.js" /* webpackChunkName: "component---src-pages-swim-academy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-training-academy-js": () => import("./../../../src/pages/training-academy.js" /* webpackChunkName: "component---src-pages-training-academy-js" */)
}

